<template>
  <div class="header_box flexJBC">
    <img src="../assets/img/logo.png" alt="" class="header_img">
    <div class="right flex ">
      <div v-for="(item, index) in menu" :key="index" class="item cur relative"
        @mouseenter="mouseenterLocal(true, 1, index)">
        <span :class="[{ active: index == active_menu }]" @click="go(item, index)"> {{ $t(item.name) }}</span>
        <div class="white_box white_box_com textAC" v-if="index == 1 && isshowinfo" @mouseleave="mouseenterLocal(false, 1, index)">
          <div v-for="(item, index) in info" :key="index" class="info_item" @click="go1(item, index)">{{ item.name }}
          </div>
        </div>
      </div>

      <div class="relative textAC flex" @mouseenter="mouseenterLocal(true, 2)">
        <div class="local_item"></div>
        <div class="cur local nowrap"> {{ localzh.name }}</div>
        <div class="white_box white_box1" v-if="isshowLocal" @mouseleave="mouseenterLocal(false, 2)">
          <div v-for="(item, index) in localArray" :key="index" class="info_item textAC cur"
            @click.stop="changeLocal(index, item)">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'
import _store from '../store'
import { useRouter } from 'vue-router';
export default {
  props: ['active_menu'],
  setup() {

    const router = useRouter()
    const { locale } = useI18n()
    const store = useStore() || _store;
    const changeLocal = (index, item) => {
      console.log('item', item);
      data.localzh = item;
      store.commit('changeLocale', item.id)
      locale.value = item.id
    }
    const go = (item, index) => {
      if (index != 1) {
        router.push({
          path: item.path
        })
      }
    }
    const go1 = (item) => {
      router.push({
        path: item.path
      })

    }
    const mouseenterLocal = (val, index, index1) => {
      if (index == 2) {
        data.isshowLocal = val
      } else if (index1 == 1) {
        data.isshowinfo = val
      }

    }
    const data = reactive({
      localzh: { name: "中文" },
      localArray: [{ name: '中文', id: 'zh' }, { name: 'English', id: 'en' }, { name: '日本語', id: 'jp' }],
      menu: [
        { name: "首页", path: '/home' },
        { name: "公司信息", },
        { name: "公司业务", path: '/company' },
        { name: "新闻资讯", path: '/news' },
        { name: "合作伙伴", path: '/cooperation' },
        { name: "联系我们", path: "/about_us" },
      ],
      info: [
        { name: "公司介绍", path: '/about_us' },
        { name: "官员介绍", path: '/officials' },
      ],
      isshowLocal: false, //显示语言
      isshowinfo: false,//显示公司信息
    })
    return {
      go,
      go1,
      changeLocal,
      mouseenterLocal,
      ...toRefs(data)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.local:hover {
  color: #578ECF;
}

.white_box1 {
  width: 100px;
  right: -40px
}


.info_item:hover {
  background-color: #578ECF;
  color: #fff;
}

.active {
  color: #578ECF;
}

.white_box {
  top: 57px;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 6px 20px rgba(20, 28, 41, 0.1);
  opacity: 1;
  line-height: 60px;
  color: #707070;
}

.white_box:nth-of-type(1) {
  left: -80%;

  width: 150px;
}

.local_item {
  height: 32px;
  width: 1px;
  background: #707070;
  opacity: 0.2;
  margin: 0 40px;
}

.right {
  flex: 1;
  max-width: 80%;


  /* 100左边距 */
  margin-left: 100px;
  /* margin-left: 20%; */

  justify-content: flex-end;
  display: flex;
  justify-content: space-evenly;
}

.item {
  margin-left: 78px;
  white-space: nowrap;
}

.item:hover {
  color: #578ECF;
}

.header_box {
  margin: 0 auto;
  padding: 24px 40px;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  z-index: 9999999;
  border-radius: 0px 40px 0px 40px;
  
}


@media screen and (max-width:1000px) {
  .header_box {
    width: 90%;
    height: 6vw;
    /* border: 1px solid red; */
    border-radius: 0px 20px 0px 20px;
    padding: 5px 5px;
    top: 6px;
    /* margin-left: 1%; */
  }

  .header_img {
    width: 10%;
    /* border: 1px solid red; */
  }

  .right {
    margin-left: 4%;
  }
  .item{
    margin-left: 0px;
    font-size: 8px;
    padding: 0 5px;
  }
  
  .local_item{
    height: 10px;
    margin: 0;
  }
  .nowrap{
    margin-left: 4px;
    font-size: 8px;
  }
  .white_box_com{
    /* border: 1px solid red; */
    width: 40px !important;
    left: 0px !important;
    top: 20px;
  }
  .info_item{
    height: 30px;
    font-size: 8px;
    line-height: 20px;
  }
  .white_box1{
    line-height: 10px;
    width: 40px;
    top: 20px;
    right: -15px;
  }
}
</style>
