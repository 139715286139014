<template >
  <div class="relative w100 home_b">
    <Header active_menu="0" />
    <swiper @swiper="onSwiper" @slideChange="onSlideChange" :pagination="pagination" :autoplay="false" :loop='false'
      :navigation="navigation">
      <swiper-slide v-for="(item, index) in img" :key="index">
        <img :src="item" class="swiper_img">
      </swiper-slide>
    </swiper>
    <div class="li_box_swi absolute flexJBC">
      <div class="li_box_l flexJBC">
        <span class=" cur swiper-button-prev turn_l" @click="swipercheck">&lt;</span>
        <span class=" cur swiper-button-next turn_r">&gt;</span>
      </div>
      <div class="li_box_r_box flex">
        <img src="../assets/img/swiper.png" alt="" class="li_box_r_imng">
        <img src="../assets/img/swiper.png" alt="" class="li_box_r_imng">
        <img src="../assets/img/swiper.png" alt="" class="li_box_r_imng">
      </div>
    </div>
  </div>
  <div class="aboutUs">
    <img src="../assets/img/re.png" alt="">
    <div class="aboutUs_text">
      {{ $t("配置'再次（重新)'。") }}<br>
      {{ $t('重复使用、转售、重估、逆转、恢复、减少、重新平衡、重建') }}<br>
      {{ $t('循环的人和事物到一个更好的状态，') }}<br>
      {{ $t('让社会和世界更幸福。') }}
    </div>
  </div>
  <div class="">
    <div class="flexjc  aboutUs1">
      <div class="aboutUs_l">
        <div class="fontS24 fw7">{{ $t('关于我们') }}</div>
        <div class="about_us_text">About us</div>
        <div class="anout_us_l_text">
          {{ $t('⽇本上市公司Aucfan Co.,Ltd.的中国⼦公司') }}
        </div>
        <div class="yewu_more cur" @click="go('/about_us')">view more</div>
      </div>
      <img src="../assets/img/aboutUS.gif" alt="" class="gif">
    </div>
    <div class="history">
      <div class="his_box textAc">
        <div class="fontS24 fw7">{{ $t('企业历史') }}</div>
        <div class="about_us_text">Enterprise history</div>
        <div class="his_box_text flexjc">
          {{
          $t('日本Aucfan集团自2004年成立至今，已经走过了整整17年的时间，在此期间公司持续进行技术创新，掌握了多项核心技术，并采用严格的质量把控体系对产品品质进行把关。市场占有率稳步提升。近两年，Aucfan在稳步开拓国内市场的同时，也在海外市场进行发力，构建全球化的业务布局！')
          }}
        </div>
        <div class="time_box relative">
          <div class="flex">
            <div class="line" :class="[{ 'his_li_ac': history_li - 1 >= index }]" v-for="(item, index) in 3"
              :key="index"></div>

          </div>
          <div class="flexJBC cir_box absolute ">
            <div v-for="(item, index) in history" @click="changehistry(index)" :key="index" class="cir_box_li cur"
              :class="[{ 'his_li_ac': history_li >= index }]">
              <div class="cir"></div>
              <div class="absolute" style="bottom:-20px;color: #999999;"
                :style="history_li == index ? 'color: #1F4A92;font-weight: bold;font-size: 40px;bottom:-50px;' : ''">
                {{ item.deta1 }}
              </div>
            </div>
          </div>
        </div>
        <div class="flexjc">
          <div style="padding-top:80px">
            <div class="blue_box flex">
              <img :src="history[history_li].img" alt="" class="history_img">
              <div class="blue_box_r">
                <div>{{ history[history_li].date }}</div>
                <div class="blue_box_r_text">
                  {{ $t(history[history_li].text1) }}<br>
                  {{ $t(history[history_li].text2) }}<br>
                  <span v-if="this.history_li==3"> {{ $t(history[history_li].text3) }}</span>
                  <!-- <span v-show="(Boolean(this.history[history_li].text3))">{{ $t(history[history_li].text3)}}</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flexjc">
      <div class="video">
        <video class="video_mp4" controls autoplay>
          <source src="../assets/img/f5848019e50aab8e6239f9def75d8496.mp4" type="video/mp4" />
        </video>
      </div>
      <!-- <video src="../assets/img/b392213657fde6d12cd3915f7950946e.mp4" autoplay loop></video> -->
    </div>
    <div class="yewu ">
      <div class="flexjc">
        <div class="yewu_l">
          <div class="fontS24 fw7">{{ $t('公司业务') }}</div>
          <div class="about_us_text">Corporate business</div>
          <div class="yewu_text">
            {{
            $t('Aucfan集团利用自成立以来培育的交易数据和AI技术，构建了产品市场价格可视化的预测模型，优化了企业库存和销售渠道的价格，主要面向中小企业、副业/独资经营者。我们正在为零售和分销行业开发库存清算解决方案。')
            }}
          </div>
          <div class="yewu_more cur" @click="go('/company')">view more</div>
        </div>
        <div class="yewu_r flex">
          <div class=" yewu_li_box yewu1">
            <img src="../assets/img/yewu1.png" alt="" class="yewu1_img">
            <div class="yewu_text1">{{ $t('⽇本最⼤的 BtoB 批发采购平台，每年分销⾦额约100 亿⽇元。') }}</div>
          </div>
          <div class=" yewu_li_box yewu1" style="margin-top:100px">
            <img src="../assets/img/yewu2.png" alt="" class="yewu2_img">
            <div class="yewu_text1">{{ $t('以⼤阪船场为据点的展览、商务会议业务。') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="guanyuan">
      <div class="yewu flexjc  ">
        <div class="yewu_l">
          <div class="fontS24 fw7">{{ $t('官员介绍') }}</div>
          <div class="about_us_text">Corporate business</div>
          <div class="yewu_more cur" @click="go('/officials')">view more</div>
        </div>
        <div v-show="guanyuanimg==1" class="guanyuan_r flexJBC">
          <div class="guanyuan_r_text">
            <div class="guanyuan_name fontS24 co33"> {{ $t('藤崎 竜成') }}</div>
            <div class="text_in " style="margin:20px 0">{{ $t('董事长兼CEO 北京外国语⼤学 毕业') }}</div>
            <div class="text_in">
              {{ $t('2018年 株式会社萬通 CEO（现任）') }}<br>
              {{ $t('2018年 北京伊藤商贸有限公司 董事长（现任）') }}<br>
              {{ $t('2021年 藤崎海南供应链有限公司 董事长（现任）') }}<br>
              {{ $t('2022年 傲可凡（海南）⽹络科技有限公司 董事长兼CEO（现任）') }}</div>
            <div class="arrow">
              <div class="arrow_iner arrow_l" @click="guanyuanCut" ></div>
              <div class="arrow_iner arrow_r" @click="guanyuanAdd"></div>
              
            </div>
          </div>
          <img src="../assets/img/guanyuan.png" alt="" class="guanyuan_r_img">
        </div>
        <div v-show="guanyuanimg==2" class="guanyuan_r flexJBC">
          <div class="guanyuan_r_text">
            <div class="guanyuan_name fontS24 co33"> {{ $t('村上 聡') }}</div>
            <div class="text_in " style="margin:20px 0">{{ $t('监事 ⽇本中央⼤学 毕业') }}</div>
            <div class="text_in">
              {{ $t('2017年 DAN consulting株式会社 董事（现任）') }}<br>
              {{ $t('2020年 株式会社美邦 董事长兼CEO（现任）') }}<br>
              {{ $t('2020年 株式会社FACT 董事长兼CEO（现任））') }}<br>
              {{ $t('2022年 株式会社GTL 董事（现任）') }}</div>
            <div class="arrow">
              <div class="arrow_iner arrow_l" @click="guanyuanCut" ></div>
              <div class="arrow_iner arrow_r" @click="guanyuanAdd"></div>
              
            </div>
          </div>
          <img src="../assets/img/18.png" alt="" class="guanyuan_r_img">
        </div>
      </div>
    </div>
    <div class="yewu  flexjc hezuo_box" style="padding-top:100px">
      <div class="yewu_l">
        <div class="fontS24 fw7">{{ $t('合作企业') }}</div>
        <div class="about_us_text">Cooperative enterprise</div>
        <div class="yewu_more cur" @click="go('/cooperation')">view more</div>
      </div>
      <img src="../assets/img/hezuo1.png" alt="" class="hezuo_img">
    </div>
    <div class="news" style="background: #F5F6FB;padding:100px 0">
      <div class="dongtai">
        <div class="flexjc" style="align-items:flex-start;">
          <div class="dongtai_son">
            <div class="fontS24 fw7">{{ $t('新闻动态') }}</div>
            <div class="about_us_text">News information</div>
          </div>
          <div class="menu_dongtai cur">
            <!-- <span :class="[{ 'dongtai_li ': dongtai_li == 0 }]">{{ $t('新闻稿') }}</span>
            <span :class="[{ 'dongtai_li': dongtai_li == 1 }]" style="margin:0 60px">{{ $t('媒体') }}</span>
            <span :class="[{ 'dongtai_li': dongtai_li == 2 }]">{{ $t('投资者关系新闻') }}</span> -->
          </div>
        </div>
        <div class="flexjc">
          <div class="time_box_dongtai" v-for="(item, index) in 3" :key="index">
            <span class="date">10-{{ index + 1 }}</span>
            <!-- :class="[{ 'active_dongtai': dongtai_li == index }]" -->
            <div class="time_dongtai"></div>
            <div class="fontS18 namefl">
              {{ $t('该基础设施整合了社会的各种“RE” 。') }}
            </div>
            <div class="text_dontai">
              {{ $t('简单来说看风景的快乐极度分裂看到房价的客服经理时代峰峻到付件 积分多少了飞机上了的看法距离开始对方就类似的飞机上来的快解封来的快放假了零售价分') }}
            </div>
            <div class="More">MORE</div>
          </div>
        </div>
        <div class="yewu_more cur" style="margin:100px auto 100px" @click="go('/news')">view more</div>
      </div>
    </div>
  </div>
  <Footer />
</template>   

<script>
import Header from '../components/Header.vue';
import Footer from '../components/follter.vue'
import { Swiper, SwiperSlide } from "swiper/vue";
import { toRaw } from '@vue/reactivity'
import "swiper/swiper-bundle.css";
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
SwiperCore.use([Autoplay, Pagination, Navigation]);
import { reactive, toRefs,ref } from 'vue'
import { useRouter } from "vue-router";

export default {

  //鼠标离开开始自动切换
  setup() {
    const router = useRouter()
    let guanyuanimg=ref(1)
    const onSwiper = (e) => {
      console.log(e)
    }
    const changehistry = (index) => {
      data.history_li = index
      console.log(data.history_li)
    }
    const swipercheck = () => {
      // SwiperCore.slideTo(index-1)
      console.log(Swiper)
      console.log(active.current - 1)
    }
    const go = (path) => {
      router.push({
        path: path
      })
    }
    let guanyuanAdd=()=>{
     
      if(guanyuanimg.value<2){
        guanyuanimg.value++
      }
      console.log(guanyuanimg.value);
    }
    let guanyuanCut=()=>{
      if(guanyuanimg.value>1){
        guanyuanimg.value--
      }
      console.log(guanyuanimg.value);
    }
    const onSlideChange = (e) => {
      let current = toRaw(e).activeIndex;
      setCurrent(current)
    }

    const data = reactive({
      changehistry,
      dongtai_li: 0,//动态
      history: [
        {

          img: require('../assets/img/history.png'),
          deta1: 2004,
          date: "2004-2007",
          text1: "1、成⽴了株式会社De facto standard 就任社长⼀职※现在公司名变更为：品牌采购「Brandear",
          text2: "2、接⼿「拍卖统计⻚⾯(暂定)」、并开始运营"
        },
        {
          img: require('../assets/img/history.png'),
          deta1: 2007,
          date: "2004-2007",
          text1: "1、成⽴了株式会社De facto standard 就任社长⼀职※现在公司名变更为：品牌采购「Brandear",
          text2: "2、接⼿「拍卖统计⻚⾯(暂定)」、并开始运营"
        },
        {
          img: require('../assets/img/2007.png'),
          deta1: 2013,
          date: "2007-2013",
          text1: "从株式会社De facto standard分割新的媒体运营事业成立株式会社aucfan2013年",
          text2: "在东京证券交易所上市（股票号码：3674）"
        },
        {
          img: require('../assets/img/logo3.png'),
          deta1: 2019,
          date: "2013-2019",
          text1: "1、运营日本国内最大规模的BtoB网站「NETSEA」",
          text2: "2、开始清算滞留库存的服务「ReValue」",
          text3: "3、收购并运营网店一元化管理系统「Tatempo Guide」"
        },
        {
          img: require('../assets/img/logo3.png'),
          deta1: 2022,
          date: "2022",
          text1: "1、收购线下展会服务公司「OSR・⼤阪船⼚流通市场」。",
          text2: "2、成⽴傲可凡（海南）⽹络科技有限公司，Aucfan中国总部。"
        },
      ],
      history_li: 1, //历史记录
      loop: true,
      local: 'zh',
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      effect: 'slide',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
        dynamicBullets: false
      }


    })

    const img = reactive([
      require("../assets/img/swiper.png"),
      require("../assets/img/swiper.png"),
      require("../assets/img/swiper.png"),
    ])
    let active = reactive({ Android: false, ios: false, current: 0, })
    // const setAndroid = (val) => {
    //   active.Android = val
    //   console.log(val, 'fsfds')
    // }

    const setCurrent = (index) => {
      active.current = index
    }
    return {
      swipercheck,
      go,
      ...toRefs(active),
      ...toRefs(data),
      onSwiper,
      img,
      onSlideChange,
      guanyuanimg,
      guanyuanAdd,
      guanyuanCut
    };
  },
  components: {
    //  modules: [Navigation, Pagination, Scrollbar, A11y],
    Footer,
    Header,
    Swiper,
    SwiperSlide,
  },
  mounted() {
    console.log(this.history_li);
  },
}
</script>
 
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped lang="less" >
// .li_box_r_box {
//   width: 80%;
// }

.arrow {
  width: 140px;
  margin-top: 40px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.arrow_iner{
  width: 21px;
  height: 18px;
}
.arrow_l{
background-image: url(./../assets/img/half_left.png);
}
.arrow_l:hover{
  background-image: url(./../assets/img/full_left.png);
}
.arrow_r{
background-image: url(./../assets/img/half_right.png);
}
.arrow_r:hover{
background-image: url(./../assets/img/full_right.png);
}

.swiper-button-prev,
.swiper-button-next {
  position: inherit;
  width: none;
}

.turn_l,
.turn_r {
  font-family: '宋体';
  color: #1F4A92;
  font-weight: 600;
  font-size: 30px;
  display: block;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 30px;
  display: none;
  right: 0;
  left: 0;
}

.time_box_dongtai:hover {

  .date,
  .namefl,
  .More {
    color: #5790CB;
  }
}

.yewu_more:hover {
  background: #1F4A92;
  color: #fff;
}

.text_dontai {
  width: 365px;
  color: #999999;
  line-height: 27px;
  margin: 44px 0 64px;
}

.active_dongtai {
  border: 1px solid #5790CB !important;
}

video {
  width: 1150px;
  display: block;
  height: 100%;
}

.time_dongtai {
  width: 416px;
  height: 0px;
  border: 1px solid #F5F6FB;
  margin-top: 40px;
  margin-bottom: 28px;

}

.date {
  width: 200px;
  color: #333333;
  font-size: 40px;
}

.time_box_dongtai {
  margin-top: 134px;
  font-size: 14px;
}

.dongtai_li {
  color: #5790CB;

}

.menu_dongtai {
  margin-left: 837px;
}

.dongtai {
  width: 80%;
  margin: 0 auto;


}

.hezuo_img {
  margin-left: 100px;
}

.text_in {
  font-size: 14px;
  font-family: Source Han Sans CN;
  line-height: 27px;
  color: #999999;
}

.guanyuan_r_text {
  padding: 0px 14px 0 70px;
  box-sizing: border-box;
}

.guanyuan_r_img {
  width: 436px;
  height: 440px;
}

.guanyuan_r {
  width: 971px;
  background: #FFFFFF;
  /* margin-left: 138px; */
  position: relative;
}

.guanyuan {
  background: #F5F6FB;
  padding-top: 100px;
}

.yewu_more {
  width: 140px;
  height: 42px;
  line-height: 42px;
  border: 1px solid #E5E5E6;
  border-radius: 24px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin-top: 176px;
  text-align: center;
}

.yewu2_img {
  width: 226px;
  height: 125px;
}

.yewu_text1 {
  max-width: 233px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 27px;
  color: #666666;
  margin-top: 83px;

}

.yewu1_img {
  width: 301px;
  height: 76px;
}

.yewu_li_box {
  width: 363px;
  text-align: center;
  padding: 73px 29px;
  box-sizing: border-box;
  border: 1px solid #1F4A92;
  margin-left: 53px;

}

.yewu1 {
  margin-top: 151px;
}

.yewu_text {
  max-width: 315px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 27px;
  color: #999999;
}

.yewu_l {
  margin-right: 70px;
}

.video {
  max-width: 90%;
  // margin: 0 auto;
}

.yewu {
  max-width: 80%;
  margin: 50px auto 0;
  padding-bottom: 100px;
}

.blue_box_r_text {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 27px;
  opacity: 0.8;
  margin-top: 22px;
}

.blue_box_r {
  max-width: 350px;
  text-align: left;
  color: #fff;
}

.history_img {
  width: 447px;
  height: 232px;
  margin-right: 80px;
}

.blue_box {
  background: #1F4A92;
  padding: 40px 100px;
  box-sizing: border-box;
  border-radius: 40px 0px 40px 0px;
  align-items: center;

}

.his_li_ac {
  background-color: rgb(162, 191, 235) !important;
}

.cir_box_li {
  width: 40px;
  height: 40px;
  background: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 480px; */
}

.cir_box_li:nth-of-type(4) {
  margin-left: 0;
}

.cir_box {
  width: 1522px;
  bottom: -20px;
}

.cir {
  width: 20px;
  height: 20px;
  background: #1F4A92;
  border-radius: 50%;
}

.time_box {
  margin: 80px 0 80px;
  display: flex;
  justify-content: center;
}


.line {
  height: 2px;
  width: 502px;
  background-color: #CCCCCC;
}

.his_box_text {
  text-align: left;
  line-height: 27px;
  font-size: 14px;
  color: #666666;
}

.history {
  /* max-width: 100%; */
  /* height: 1002px; */
  background: #F5F6FB;
  text-align: center;
  /* padding: 100px 140px; */
  box-sizing: border-box;
  /* margin: 0 auto; */
  /* overflow: hidden; */
}

.his_box {
  max-width: 80%;
  /* height: 80px; */
  margin: 100px auto;
  padding: 100px 0;
}

.aboutUs1 {
  max-width: 80%;
  margin: 0 auto;
}

.gif {
  max-width: 60%;
  /* height: 475px; */
}

.anout_us_l_text {
  font-size: 14px;
  line-height: 27px;
  color: #666666;
  max-width: 678px;
}

.aboutUs_l {
  text-align: left;
  // /* max-width: 678px; */
  margin-right: 149px;

}

.about_us_text {
  color: #E5E5E6;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 48px;
}

.aboutUs_text {
  line-height: 30px;
  font-size: 20px;
  color: #666666;
  margin-top: 80px;
  margin-bottom: 163px;
}

.aboutUs {
  padding: 150px 0 100px;
  box-sizing: border-box;
  max-width: 80%;
  /* background-color: #000; */
  margin: 0 auto;
  text-align: center;
}

.li_box_r_imng {
  width: 176px;
  height: 80px;
  margin-right: 20px;
}

.li_box_r_imng:nth-of-type(1) {
  margin-left: 500px;

}



.turn_r {
  margin-left: 101px;
}

.li_box_swi {
  width: 80;
  padding: 12px 0;
  background-color: #fff;
  bottom: -40px;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 6px 20px rgba(20, 28, 41, 0.1);
}

.li_box_l {
  /* min-width: 10.94%; */
  padding: 36px 40px;
  align-items: center;
  line-height: 64px;
  box-sizing: border-box;
  border-right: 1px solid rgba(204, 204, 204, 0.502);
}

.lis_imgbox {
  padding: 10px 20px;
  box-sizing: border-box;
}

.swiper-container {
  width: 100%;
  /* position: absolute;
  top: 0;
  height: 887px !important;
  padding: 0 118px;
  box-sizing: border-box; */
}

.swiper-slide {
  width: 100% !important;
  text-align: center;
}

// 9.8号更改
.swiper_img {
  width: 1440px;
}

.line {
  width: 402px;
}

.cir_box {
  width: 1250px;
}







@media screen and (max-width : 1000px) {
  .swiper-slide>img {
    width: 100%;
  }

  .li_box_swi {
    // display: none;
    // width: 100%;
  }

  .video_mp4 {
    margin-top: 20px;
    width: 80vw;
  }

  .turn_r {
    margin-left: 0px;
  }

  .turn_l,
  .turn_r {
    font-size: 8px;
    text-align: center;
  }

  .li_box_r_imng {
    width: 70px;
    height: 35px;
    // margin-left: 0;
  }

  .li_box_r_imng:nth-child(1) {
    margin-left: 10px;
  }

  .li_box_l {
    padding: 0;
  }

  .aboutUs {
    padding: 0px;
    padding-top: 60px;
  }

  .aboutUs>img {
    width: 50px;
  }

  .aboutUs_text {
    margin: 20px 0px;
    font-size: 8px;
    line-height: 10px;
  }

  .aboutUs_l {
    margin-right: 0px;
  }

  .fontS24,
  .about_us_text {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .anout_us_l_text {
    font-size: 8px;
    line-height: 12px;
  }

  .yewu_more {
    margin-top: 10px;
    width: 80px;
    height: 20px;
    line-height: 20px;
    font-size: 8px;
  }

  .his_box {
    padding: 30px;
    margin-bottom: 0;
  }

  .time_box {
    margin: 0;
  }

  .line {
    width: 33vw;
  }

  .cir_box {
    width: 100vw;
    bottom: -10px;
  }

  .his_box_text {
    margin-bottom: 20px;
  }

  .cir_box_li {
    width: 20px;
    height: 20px;
  }

  .cir {
    width: 10px;
    height: 10px;
  }

  .absolute {
    font-size: 8px;
  }

  .blue_box {
    width: 80vw;
    height: 30vw;
    padding: 5vw 3vw;
    border-radius: 5vw 0 5vw 0;
  }

  .blue_box>img {
    width: 40vw;
    height: 20vw;
    margin-right: 5vw;
  }

  .blue_box_r {
    font-size: 12px;
  }

  .blue_box_r_text {
    margin-top: 5px;
    font-size: 8px;
    line-height: 10px;
    width: 28vw;
  }

  .yewu_l {
    margin-right: 10px;
  }

  .yewu_li_box {
    // margin-left: 5px;
    width: 20vw;
    margin: 0;
    padding: 0;
  }

  .yewu_text {
    width: 100px;
    // border: 1px solid red;
    font-size: 8px;
    line-height: 10px;
  }

  .yewu_li_box>img {
    // margin: 0 5px;
    width: 10vw;
    height: 8vw;
  }

  .yewu_text1 {
    margin-top: 2px;
    font-size: 8px;
    line-height: 10px;
  }

  .yewu {
    margin: 10px;
    // margin-top: 50px;
    padding: 20px;
  }

  .guanyuan {
    padding-top: 0;
    // border: 1px solid red;
  }

  .guanyuan_r_img {
    width: 100px;
    height: 120px;
  }

  .guanyuan_r_text {
    padding: 0;
    padding-left: 10px;
  }

  .text_in {
    font-size: 6px;
    line-height: 8px;
  }

  .hezuo_img {
    width: 50vw;
    margin-left: 10px;
  }

  .hezuo_box {
    margin-top: 10px;
    padding-top: 10px !important;
  }

  .time_dongtai {
    display: none;
  }

  .menu_dongtai {
    margin-left: 20px;
    font-size: 10px;
  }

  .menu_dongtai>span {
    margin: 0 2px;
  }

  .text_dontai {
    width: 30vw;
    font-size: 8px;
    line-height: 10px;
    margin-top: 18px;
    margin-bottom: 18px;
  }

  .dongtai {
    width: 95vw !important;
  }

  .news {
    padding-top: 20px !important;
    // padding-bottom: 50px !important;
  }

  .time_box_dongtai {
    margin-top: 10px;
  }

  .time_box_dongtai>span {
    font-size: 28px;
  }

  .namefl {
    margin-top: 20px;
    font-size: 10px;
  }

  .More {
    font-size: 8px;
  }

  .yewu_more {
    margin: 50px auto !important;
  }
  .arrow{
    width: 80px;
    margin-top: 10px;
  }
 
}
</style>
