<template>
    <div class="footer ">
        <div class="boxbox flexjc">
            <div class="box ">
                <div class="lis">
                    傲可凡（海南）网络科技有限公司
                </div>
                <div class="dete">服务时间：周一至周五9:00-18:00</div>
                <div class="flex">
                    <img class="img" src="../assets/img/weibo.png" alt="">
                    <img class="img" src="../assets/img/weixin.png" alt="">
                    <img class="img" src="../assets/img/qq.png" alt="">
                </div>
            </div>
            <div class="box1 box">
                <div class="lis">
                    日本总部官网
                </div>
                <div class="dete">Official website of headquarters</div>
                <div class="com_link">https://aucfan.co.jp/</div>
            </div>
            <div class="box">
                <div class="lis">
                    公司
                </div>
                <div class="dete">Business cooperation</div>
                <div class="add">地址：海南省海⼝市琼⼭区海航豪庭南苑⼆区1栋4302</div>
                <div class="add">企业邮箱： info@aucfan-cn.com</div>
            </div>
        </div>
    </div>
</template>   

<script>
import { reactive, toRefs } from "vue";


export default {
    name: 'Home',

    setup() {
        let active = reactive({

        })

        return {
            ...toRefs(active),
        };
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.add {
    /* margin-bottom: 18px; */
}



.img {
    width: 30px;
    height: 30px;

}

.img:nth-of-type(2) {
    margin: 0 26px;
}

.dete {
    font-size: 14px;
    font-family: Source Han Sans CN;
margin-top: 5px;
margin-bottom: 5px;
    color: #FFFFFF;
    opacity: 0.6;

}

.footer {
    color: #fff;
    width: 100%;
    /* height: 372px; */
    background: #1F4A92;
}

.boxbox {
    max-width: 80%;
    padding: 120px 0;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    margin: 0 auto;
}


/* 9.8号添加 */
.box{
    height: 100px;
}
.com_link{
    margin-top: 5px;
}
.flex{
    margin-top: 5px;
}
.add{
    margin-top: 5px;
    font-size: 12px;
    line-height: 12px;
}



@media screen and (max-width:1000px) {
    .boxbox {
        padding: 0;
    }
    .box1{
        margin: 0;
    }
    .lis{
        font-size: 14px;
        margin-bottom: 5px;
    
    }
    .dete{
        font-size: 6px;
        /* margin: 10px 0 10px; */
    }
    .flex>img{
        width: 20px;
        height: 20px;
    }
    .dete+div{
        font-size: 6px;
    }
    .add{
        font-size: 8px;
        line-height: 10px;
    }
    .dete{
        margin-bottom: 15px;
    }
    .boxbox{
    padding-top: 20px !important;
  }
}
</style>
